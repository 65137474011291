import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { motion } from "framer-motion";
import { ChartBarIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Translations } from "./App";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Result {
  result: number;
  blockNumber: number;
  timestamp: number;
}

interface ChartsSectionProps {
  t: Translations;
  recentResults: Result[] | null;
  isLoading?: boolean;
  error?: string | null;
}

const ChartsSection: React.FC<ChartsSectionProps> = ({
  t,
  recentResults,
  isLoading = false,
  error = null,
}) => {
  const safeRecentResults = recentResults ?? [];
  const [recentResultsScrollIndex, setRecentResultsScrollIndex] = useState<number>(0);
  const [oddEvenTrendScrollIndex, setOddEvenTrendScrollIndex] = useState<number>(0);
  const recentResultsRef = useRef<HTMLDivElement>(null);
  const oddEvenTrendRef = useRef<HTMLDivElement>(null);

  const { oddCount, evenCount, streakData } = useMemo(() => {
    if (!safeRecentResults.length) {
      return { oddCount: 0, evenCount: 0, streakData: [{ isOdd: true, count: 0 }] };
    }
    const odds = safeRecentResults.filter((item) => item.result % 2 === 1).length;
    const evens = safeRecentResults.filter((item) => item.result % 2 === 0).length;
    const streaks: { isOdd: boolean; count: number }[] = [];
    let currentStreak: { isOdd: boolean; count: number } = { isOdd: false, count: 0 };
    const data = safeRecentResults.slice(-100);
    data.forEach((item, index) => {
      const isOdd = item.result % 2 === 1;
      if (index === 0) {
        currentStreak = { isOdd, count: 1 };
      } else if (isOdd === currentStreak.isOdd) {
        currentStreak.count += 1;
      } else {
        streaks.push({ ...currentStreak });
        currentStreak = { isOdd, count: 1 };
      }
      if (index === data.length - 1) streaks.push({ ...currentStreak });
    });
    return {
      oddCount: odds,
      evenCount: evens,
      streakData: streaks.length > 0 ? streaks : [{ isOdd: true, count: 0 }],
    };
  }, [safeRecentResults]);

  const oddEvenRatioData = useMemo(() => ({
    labels: [t.odd, t.even],
    datasets: [{
      label: "Count",
      data: [oddCount, evenCount],
      backgroundColor: ["#FF5555", "#10B981"],
    }],
  }), [t.odd, t.even, oddCount, evenCount]);

  const calculateStreakStats = useCallback(() => {
    const streaks = streakData;
    const oddStreaks = streaks.filter((s) => s.isOdd && s.count >= 2).length;
    const evenStreaks = streaks.filter((s) => !s.isOdd && s.count >= 2).length;
    const maxOddStreak = Math.max(...streaks.filter((s) => s.isOdd).map((s) => s.count), 0);
    const maxEvenStreak = Math.max(...streaks.filter((s) => !s.isOdd).map((s) => s.count), 0);
    return { oddStreaks, evenStreaks, maxOddStreak, maxEvenStreak };
  }, [streakData]);

  const { oddStreaks, evenStreaks, maxOddStreak, maxEvenStreak } = calculateStreakStats();

  const streakAnalysisData = useMemo(() => ({
    labels: [`${t.odd} (≥2)`, `${t.even} (≥2)`, `${t.odd} Max`, `${t.even} Max`],
    datasets: [{
      label: "Streaks",
      data: [oddStreaks, evenStreaks, maxOddStreak, maxEvenStreak],
      backgroundColor: ["#FF5555", "#10B981", "#FFAA33", "#FFFF66"],
    }],
  }), [t.odd, t.even, oddStreaks, evenStreaks, maxOddStreak, maxEvenStreak]);

  const calculateHotColdNumbers = useCallback(() => {
    const counts = Array(10).fill(0);
    safeRecentResults.slice(-100).forEach((item) => {
      if (item.result >= 0 && item.result <= 9) counts[item.result]++;
    });
    const maxCount = Math.max(...counts);
    const minCount = Math.min(...counts.filter((c) => c > 0), maxCount);
    const hotNumbers = counts.reduce((acc, count, i) => (count === maxCount ? [...acc, i] : acc), [] as number[]);
    const coldNumbers = counts.reduce((acc, count, i) => (count === minCount ? [...acc, i] : acc), [] as number[]);
    return { hotNumbers, hotCount: maxCount, coldNumbers, coldCount: minCount };
  }, [safeRecentResults]);

  const { hotNumbers, hotCount, coldNumbers, coldCount } = calculateHotColdNumbers();

  const calculatePrediction = useCallback(() => {
    const counts = Array(10).fill(0);
    safeRecentResults.slice(-100).forEach((item) => {
      if (item.result >= 0 && item.result <= 9) counts[item.result]++;
    });
    return counts.map((count) => {
      const baseProbability = 10;
      const adjusted = Math.max(1, Math.min(99, baseProbability - (count - 10)));
      return Math.min(95, adjusted * 5).toFixed(1);
    });
  }, [safeRecentResults]);

  const predictionValues = calculatePrediction();
  const maxPrediction = Math.max(...predictionValues.map(parseFloat));
  const minPrediction = Math.min(...predictionValues.map(parseFloat));

  const predictionData = useMemo(() => ({
    labels: Array.from({ length: 10 }, (_, i) => i.toString()),
    datasets: [{
      label: "Probability (%)",
      data: predictionValues,
      backgroundColor: predictionValues.map((value) =>
        parseFloat(value) === minPrediction ? "#FF5555" : parseFloat(value) === maxPrediction ? "#10B981" : "#AAAAAA"
      ),
    }],
  }), [predictionValues, minPrediction, maxPrediction]);

  const ITEMS_PER_PAGE_RECENT = 10;
  const ITEMS_PER_PAGE_STREAK = 10;

  const renderRecentResults = useCallback(() => {
    const data = safeRecentResults.slice(-100);
    if (!data.length) return <p className="text-gray-400">{t.noBets}</p>;

    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE_RECENT);
    const startIndex = Math.max(0, totalItems - (recentResultsScrollIndex + 1) * ITEMS_PER_PAGE_RECENT);
    const endIndex = Math.max(0, totalItems - recentResultsScrollIndex * ITEMS_PER_PAGE_RECENT);
    const visibleData = data.slice(startIndex, endIndex);

    const handleScrollLeft = () => {
      if (recentResultsScrollIndex < totalPages - 1) {
        setRecentResultsScrollIndex(recentResultsScrollIndex + 1);
        recentResultsRef.current?.scrollBy({ left: -300, behavior: "smooth" });
      }
    };

    const handleScrollRight = () => {
      if (recentResultsScrollIndex > 0) {
        setRecentResultsScrollIndex(recentResultsScrollIndex - 1);
        recentResultsRef.current?.scrollBy({ left: 300, behavior: "smooth" });
      }
    };

    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-2">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleScrollLeft}
            disabled={recentResultsScrollIndex >= totalPages - 1}
            className="p-2 text-yellow-400 disabled:opacity-50"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleScrollRight}
            disabled={recentResultsScrollIndex === 0}
            className="p-2 text-yellow-400 disabled:opacity-50"
          >
            <ChevronRightIcon className="h-6 w-6" />
          </motion.button>
        </div>
        <div
          ref={recentResultsRef}
          className="flex flex-wrap gap-2 justify-start items-end flex-grow overflow-x-auto"
          style={{ scrollSnapType: "x mandatory" }}
        >
          {visibleData.map((item, index) => {
            const isLatest = index === visibleData.length - 1 && startIndex + index === data.length - 1;
            return (
              <motion.div
                key={item.blockNumber}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="flex flex-col items-center shrink-0"
              >
                <div
                  className={`rounded-full flex items-center justify-center text-white font-bold aspect-square ${
                    item.result % 2 === 1 ? "bg-red-600" : "bg-green-600"
                  } ${isLatest ? "w-12 h-12 text-xl pulse-latest neon-effect" : "w-8 h-8 text-sm"}`}
                >
                  {item.result}
                </div>
                <div className="mt-1 text-center text-xs text-gray-400">
                  <p>{t.block}: {item.blockNumber}</p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    );
  }, [safeRecentResults, t, recentResultsScrollIndex]);

  const renderOddEvenTrend = useCallback(() => {
    if (!streakData.length) return <p className="text-gray-400">{t.noBets}</p>;

    const totalItems = streakData.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE_STREAK);
    const startIndex = Math.max(0, totalItems - (oddEvenTrendScrollIndex + 1) * ITEMS_PER_PAGE_STREAK);
    const endIndex = Math.max(0, totalItems - oddEvenTrendScrollIndex * ITEMS_PER_PAGE_STREAK);
    const visibleStreaks = streakData.slice(startIndex, endIndex);

    const handleScrollLeft = () => {
      if (oddEvenTrendScrollIndex < totalPages - 1) {
        setOddEvenTrendScrollIndex(oddEvenTrendScrollIndex + 1);
        oddEvenTrendRef.current?.scrollBy({ left: -300, behavior: "smooth" });
      }
    };

    const handleScrollRight = () => {
      if (oddEvenTrendScrollIndex > 0) {
        setOddEvenTrendScrollIndex(oddEvenTrendScrollIndex - 1);
        oddEvenTrendRef.current?.scrollBy({ left: 300, behavior: "smooth" });
      }
    };

    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-2">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleScrollLeft}
            disabled={oddEvenTrendScrollIndex >= totalPages - 1}
            className="p-2 text-yellow-400 disabled:opacity-50"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleScrollRight}
            disabled={oddEvenTrendScrollIndex === 0}
            className="p-2 text-yellow-400 disabled:opacity-50"
          >
            <ChevronRightIcon className="h-6 w-6" />
          </motion.button>
        </div>
        <div
          ref={oddEvenTrendRef}
          className="flex flex-row gap-1 h-96 overflow-x-auto items-end"
          style={{ scrollSnapType: "x mandatory" }}
        >
          {visibleStreaks.map((streak, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex flex-col-reverse justify-end gap-1 shrink-0 items-center"
            >
              {Array.from({ length: streak.count }, (_, i) => (
                <div
                  key={i}
                  className={`rounded-full ${
                    index === visibleStreaks.length - 1 && i === streak.count - 1 && startIndex + index === streakData.length - 1
                      ? "w-8 h-8 pulse-latest neon-effect mx-auto"
                      : "w-6 h-6"
                  } ${streak.isOdd ? "bg-red-600" : "bg-green-600"}`}
                />
              ))}
            </motion.div>
          ))}
        </div>
      </div>
    );
  }, [streakData, t, oddEvenTrendScrollIndex]);

  const hotColdNumbersData = useMemo(() => {
    const counts = Array(10)
      .fill(0)
      .map((_, i) => safeRecentResults.slice(-100).filter((item) => item.result === i).length);
    return {
      labels: Array.from({ length: 10 }, (_, i) => i.toString()),
      datasets: [{
        label: "Occurrences",
        data: counts,
        backgroundColor: Array(10)
          .fill("#AAAAAA")
          .map((_, i) => (hotNumbers.includes(i) ? "#10B981" : coldNumbers.includes(i) ? "#FF5555" : "#AAAAAA")),
      }],
    };
  }, [safeRecentResults, hotNumbers, coldNumbers]);

  const charts = useMemo(() => [
    { title: t.recentResults, content: renderRecentResults() },
    { title: t.oddEvenTrend, content: renderOddEvenTrend() },
    {
      title: t.oddEvenRatio,
      content: (
        <div className="text-sm p-4">
          <p className="mb-2">{t.odd}: {oddCount} ({((oddCount / (oddCount + evenCount || 1)) * 100).toFixed(1)}%)</p>
          <p className="mb-2">{t.even}: {evenCount} ({((evenCount / (oddCount + evenCount || 1)) * 100).toFixed(1)}%)</p>
          <Bar
            data={oddEvenRatioData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: { legend: { display: false }, tooltip: { enabled: true } },
              scales: {
                y: { max: Math.max(oddCount, evenCount) + 5, min: 0, title: { display: true, text: "Count" } },
                x: {},
              },
            } as ChartOptions<"bar">}
          />
        </div>
      ),
    },
    {
      title: t.streakAnalysis,
      content: (
        <div className="p-4">
          <Bar
            data={streakAnalysisData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: { legend: { display: false }, tooltip: { enabled: true } },
              scales: {
                y: { max: Math.max(oddStreaks, evenStreaks, maxOddStreak, maxEvenStreak) + 5, min: 0, title: { display: true, text: "Count" } },
                x: {},
              },
            } as ChartOptions<"bar">}
          />
        </div>
      ),
    },
    {
      title: t.hotColdNumbers,
      content: (
        <div className="text-sm p-4">
          <p>{`Hot Number(s): ${hotNumbers.length ? hotNumbers.join(", ") : "N/A"} (${hotCount} times)`}</p>
          <p>{`Cold Number(s): ${coldNumbers.length ? coldNumbers.join(", ") : "N/A"} (${coldCount} times)`}</p>
          <Bar
            data={hotColdNumbersData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: { legend: { display: false }, tooltip: { enabled: true } },
              scales: {
                y: { max: Math.max(...hotColdNumbersData.datasets[0].data) + 5, min: 0, title: { display: true, text: "Count" } },
                x: {},
              },
            } as ChartOptions<"bar">}
          />
        </div>
      ),
    },
    {
      title: t.prediction,
      content: (
        <div className="p-4">
          <Bar
            data={predictionData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: { legend: { display: false }, tooltip: { callbacks: { label: (context) => `${context.raw}%` } } },
              scales: {
                y: { min: 0, max: 100, title: { display: true, text: "Probability (%)" } },
                x: {},
              },
            } as ChartOptions<"bar">}
          />
        </div>
      ),
    },
  ], [
    t, oddCount, evenCount, oddEvenRatioData, streakAnalysisData, hotNumbers, hotCount, coldNumbers, coldCount,
    predictionData, renderRecentResults, renderOddEvenTrend, safeRecentResults, oddStreaks, evenStreaks,
    maxOddStreak, maxEvenStreak, hotColdNumbersData,
  ]);

  if (isLoading) return <div className="mt-6 text-center text-yellow-400">{t.loading}</div>;
  if (error) return <div className="mt-6 text-center text-red-500">{t.error}: {error}</div>;

  return (
    <div className="mt-6">
      <div className="hidden md:grid grid-cols-2 gap-6">
        {charts.map((chart, index) => (
          <motion.div
            key={chart.title}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-800 p-4 rounded-lg shadow-lg card-glow card-pulse chart-container h-[400px] flex flex-col"
          >
            <h2 className="text-xl font-semibold text-yellow-400 glow-text mb-3 flex items-center">
              <ChartBarIcon className="h-6 w-6 mr-2" /> {chart.title}
            </h2>
            <div className="flex-grow flex flex-col justify-end">{chart.content}</div>
          </motion.div>
        ))}
      </div>

      <div className="md:hidden space-y-6">
        {charts.map((chart, index) => (
          <motion.div
            key={chart.title}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-800 p-4 rounded-lg shadow-lg card-glow card-pulse chart-container h-[400px] flex flex-col"
          >
            <h2 className="text-xl font-semibold text-yellow-400 glow-text mb-3 flex items-center">
              <ChartBarIcon className="h-6 w-6 mr-2" /> {chart.title}
            </h2>
            <div className="flex-grow flex flex-col justify-end">{chart.content}</div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ChartsSection;