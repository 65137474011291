import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { toast, ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { Bars3Icon, WalletIcon, XMarkIcon } from "@heroicons/react/24/solid";
import GameSection from "./GameSection";
import ChartsSection from "./ChartsSection";
import Navigation from "./Navigation";
import { ethers } from "ethers";

import {
  ensureNetwork,
  addPiTokenToWallet,
  piCoinContract,
  getParityGameContractWithSigner,
  getPiCoinContractWithSigner,
  refundBet,
  getRecentResults,
  getRecentWinners,
  hasUserClaimed,
  getPiTokenBalance,
  getRemainingSupply,
  parseEther,
  MaxUint256,
  parityGameAddress,
  getUserBets,
  getUserBetCount,
} from "./contract";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Define the provider with proper type
const provider = window.ethereum
  ? new ethers.BrowserProvider(window.ethereum)
  : null;

// Disable console.error in production
if (process.env.NODE_ENV === "production") {
  console.error = () => {};
}

// Interfaces
export interface Translations {
  title: string;
  connectWallet: string;
  disconnectWallet: string;
  piPool: string;
  bet: string;
  odd: string;
  even: string;
  claimReward: string;
  recentResults: string;
  latestResult: string;
  winners: string;
  history: string;
  approveTokens: string;
  games: string;
  leaderboard: string;
  howToPlay: string;
  community: string;
  officialWebsite: string;
  x: string;
  telegram: string;
  discord: string;
  oddEvenTrend: string;
  oddEvenRatio: string;
  streakAnalysis: string;
  prediction: string;
  blockTrend: string;
  hotColdNumbers: string;
  million: string;
  billion: string;
  trillion: string;
  balance: string;
  noBets: string;
  boundReferrer: string;
  developing: string;
  refer: string;
  referralLink: string;
  copyLink: string;
  bindRefer: string;
  enterReferAddress: string;
  confirmBind: string;
  block: string;
  claimableAmount: string;
  claimOnChain: string;
  close: string;
  getTestnetTokens: string;
  getTestnetTokensGuide: string;
  testnetTokenInstruction: string;
  refund: string;
  noRewards: string;
  noRefundableBets: string;
  paused: string;
  copyReferralLink: string;
  copied: string;
  claimPiTokens: string;
  alreadyClaimed: string;
  remainingSupply: string;
  piCoinStats: string;
  totalSupply: string;
  totalMinted: string;
  totalClaims: string;
  loading: string;
  error: string;
  errorFetchingBalance: string;
  zeroBalanceWarning: string;
  switchToBSCTestnet: string;
  connectWalletPrompt: string;
  invalidBetAmount: string;
  insufficientBalance: string;
  betPlacedSuccess: string;
  betFailed: string;
  tokensApprovedSuccess: string;
  approveFailed: string;
  refundFailed: string;
}

interface Bet {
  id?: string;
  amount: string;
  betType: number;
  betBlock: number;
  targetBlock: number;
  settled: boolean;
  refunded?: boolean;
  result?: number;
  won?: boolean;
}

interface RawBet {
  amount: bigint;
  betType: number;
  betBlock: number;
  targetBlock: number;
  settled: boolean;
  result?: number;
  won?: boolean;
}

interface Winner {
  player: string;
  reward: bigint;
  timestamp: number;
}

// Translations (English and Chinese)
const translations: { en: Translations; zh: Translations } = {
  en: {
    title: "PICOIN Game Center",
    connectWallet: "Connect Wallet",
    disconnectWallet: "Disconnect Wallet",
    piPool: "PI Pool",
    bet: "Bet",
    odd: "Odd",
    even: "Even",
    claimReward: "Claim Your Rewards",
    recentResults: "Recent Results",
    latestResult: "Latest Result",
    winners: "Winners",
    history: "Betting History",
    approveTokens: "Approve Tokens",
    games: "Game Library",
    leaderboard: "Global Leaderboard",
    howToPlay: "Game Guide",
    community: "Community Hub",
    officialWebsite: "Official Website",
    x: "X",
    telegram: "Telegram",
    discord: "Discord",
    oddEvenTrend: "Odd/Even Trend",
    oddEvenRatio: "Odd/Even Ratio",
    streakAnalysis: "Streak Analysis",
    prediction: "Prediction",
    blockTrend: "Block Trend",
    hotColdNumbers: "Hot/Cold Numbers",
    million: "M",
    billion: "B",
    trillion: "T",
    balance: "Balance",
    noBets: "No bets yet",
    boundReferrer: "Bound Referrer",
    developing: "Developing",
    refer: "Referral Program",
    referralLink: "Referral Link",
    copyLink: "Copy Link",
    bindRefer: "Link a Referrer",
    enterReferAddress: "Enter Referrer Address",
    confirmBind: "Confirm Binding",
    block: "Block",
    claimableAmount: "Claimable Amount",
    claimOnChain: "Claim On-Chain",
    close: "Close",
    getTestnetTokens: "Claim Testnet Tokens",
    getTestnetTokensGuide: "Testnet Tokens Guide",
    testnetTokenInstruction:
      "To acquire testnet tokens, click the 'Claim Testnet Tokens' button in the navigation menu to claim your PI tokens directly.",
    refund: "Request a Refund",
    noRewards: "No rewards available to claim",
    noRefundableBets: "No refundable bets available",
    paused: "Paused",
    copyReferralLink: "Copy Your Referral Link",
    copied: "Copied!",
    claimPiTokens: "Claim 314,159,265,358 PI",
    alreadyClaimed: "Already Claimed",
    remainingSupply: "Remaining Supply",
    piCoinStats: "PiCoin Stats",
    totalSupply: "Total Supply",
    totalMinted: "Total Minted",
    totalClaims: "Total Claims",
    loading: "Loading...",
    error: "Error",
    errorFetchingBalance: "Error fetching balance",
    zeroBalanceWarning: "Your balance is zero.",
    switchToBSCTestnet: "Please switch to BSC Testnet.",
    connectWalletPrompt: "Please connect your wallet.",
    invalidBetAmount: "Invalid bet amount",
    insufficientBalance: "Insufficient balance",
    betPlacedSuccess: "Bet placed successfully!",
    betFailed: "Failed to place bet",
    tokensApprovedSuccess: "Tokens approved successfully!",
    approveFailed: "Failed to approve tokens",
    refundFailed: "Failed to refund bet",
  },
  zh: {
    title: "PICOIN 游戏中心",
    connectWallet: "连接钱包",
    disconnectWallet: "断开钱包",
    piPool: "PI 奖池",
    bet: "投注",
    odd: "奇数",
    even: "偶数",
    claimReward: "领取您的奖励",
    recentResults: "最近结果",
    latestResult: "最新结果",
    winners: "赢家",
    history: "投注历史",
    approveTokens: "授权代币",
    games: "游戏库",
    leaderboard: "全球排行榜",
    howToPlay: "游戏指南",
    community: "社区中心",
    officialWebsite: "官方网站",
    x: "X",
    telegram: "Telegram",
    discord: "Discord",
    oddEvenTrend: "奇偶趋势",
    oddEvenRatio: "奇偶比例",
    streakAnalysis: "连胜分析",
    prediction: "预测",
    blockTrend: "区块趋势",
    hotColdNumbers: "热门/冷门数字",
    million: "百万",
    billion: "十亿",
    trillion: "万亿",
    balance: "余额",
    noBets: "暂无投注",
    boundReferrer: "已绑定推荐人",
    developing: "开发中",
    refer: "推荐计划",
    referralLink: "推荐链接",
    copyLink: "复制链接",
    bindRefer: "绑定推荐人",
    enterReferAddress: "输入推荐人地址",
    confirmBind: "确认绑定",
    block: "区块",
    claimableAmount: "可领取金额",
    claimOnChain: "链上领取",
    close: "关闭",
    getTestnetTokens: "领取测试网代币",
    getTestnetTokensGuide: "测试网代币指南",
    testnetTokenInstruction:
      "要获取测试网代币，请在导航菜单中点击“领取测试网代币”按钮，直接领取您的 PI 代币。",
    refund: "请求退款",
    noRewards: "没有可领取的奖励",
    noRefundableBets: "没有可退款的下注",
    paused: "已暂停",
    copyReferralLink: "复制您的推荐链接",
    copied: "已复制!",
    claimPiTokens: "领取 314,159,265,358 PI",
    alreadyClaimed: "已领取",
    remainingSupply: "剩余供应量",
    piCoinStats: "PiCoin 统计",
    totalSupply: "总发行量",
    totalMinted: "已铸造量",
    totalClaims: "总领取次数",
    loading: "加载中...",
    error: "错误",
    errorFetchingBalance: "获取余额失败",
    zeroBalanceWarning: "余额为 0",
    switchToBSCTestnet: "请切换到 BSC 测试网",
    connectWalletPrompt: "请先连接钱包",
    invalidBetAmount: "投注金额无效",
    insufficientBalance: "余额不足",
    betPlacedSuccess: "投注成功！",
    betFailed: "投注失败",
    tokensApprovedSuccess: "授权成功！",
    approveFailed: "授权失败",
    refundFailed: "退款失败",
  },
};

// Reusable Modal Component
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = memo(({ isOpen, onClose, title, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[10002]"
        >
          <motion.div
            ref={modalRef}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto relative"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-yellow-400 hover:text-yellow-300 focus:outline-none focus:ring-2 focus:ring-yellow-400 rounded"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
            <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">{title}</h2>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
Modal.displayName = "Modal";

// Error Boundary Component
interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    toast.error(`An unexpected error occurred: ${error.message}. Please refresh the page.`);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center h-screen text-center">
          <h1 className="text-3xl font-bold text-yellow-400">Something went wrong.</h1>
          <p className="text-gray-400 mt-2">Please refresh the page or try again later.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

// Main App Component
const App: React.FC = () => {
  const [language, setLanguage] = useState<"en" | "zh">("en");
  const [account, setAccount] = useState<string | null>(null);
  const [balance, setBalance] = useState<string>("0");
  const [piPool, setPiPool] = useState<string>("0");
  const [recentResults, setRecentResults] = useState<
    { result: number; blockNumber: number; timestamp: number }[]
  >([]);
  const [recentWinners, setRecentWinners] = useState<Winner[]>([]);
  const [isOdd, setIsOdd] = useState<boolean>(true);
  const [betAmount, setBetAmount] = useState<string>("");
  const [isTokensApproved, setIsTokensApproved] = useState<boolean>(false);
  const [showNav, setShowNav] = useState<boolean>(false);
  const [showLeaderboard, setShowLeaderboard] = useState<boolean>(false);
  const [showHowToPlay, setShowHowToPlay] = useState<boolean>(false);
  const [showReferModal, setShowReferModal] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [showTestnetGuideModal, setShowTestnetGuideModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [referrerAddress, setReferrerAddress] = useState<string>("");
  const [boundReferrer, setBoundReferrer] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<string>("Parity");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasClaimed, setHasClaimed] = useState<boolean>(false);
  const [remainingSupply, setRemainingSupply] = useState<string>("0");
  const [lastRefresh, setLastRefresh] = useState<number>(0);
  const [refundableBets, setRefundableBets] = useState<Bet[]>([]);
  const t = translations[language];

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      toast.error(`Error: ${event.error?.message || "Unknown"}`);
    };
    window.addEventListener("error", handleError);
    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  useEffect(() => {
    setRefundableBets(
      bets.filter((bet) => !bet.settled && !(bet.refunded ?? false))
    );
  }, [bets]);

  const playSound = useCallback(() => {
    const audio = new Audio("/click.mp3");
    audio.play().catch(() => {});
  }, []);

  const formatNumber = useCallback((value: string | number): string => {
    const num = typeof value === "string" ? parseFloat(value) : value;
    if (isNaN(num)) return "0";
    if (num >= 1e12) return `${(num / 1e12).toFixed(2)}${t.trillion}`;
    if (num >= 1e9) return `${(num / 1e9).toFixed(2)}${t.billion}`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(2)}${t.million}`;
    return num.toFixed(2);
  }, [t]);

  const loadBalance = useCallback(
    async (address: string) => {
      const balance = await getPiTokenBalance(address);
      setBalance(balance || "0");
      if (!balance || parseFloat(balance) === 0) {
        toast.info(t.zeroBalanceWarning);
      }
    },
    [t]
  );

  const loadPiPool = useCallback(async () => {
    const poolBalance = await piCoinContract.balanceOf(parityGameAddress);
    setPiPool(ethers.formatUnits(poolBalance || 0, 18));
  }, []);

  const fetchRecentResults = useCallback(async () => {
    const results = await getRecentResults();
    if (!results || !provider) {
      setRecentResults([]);
      return;
    }
    const currentBlock = await provider.getBlockNumber();
    setRecentResults(
      results.map((result, i) => ({
        result,
        blockNumber: currentBlock - (results.length - i - 1),
        timestamp: Date.now() - (results.length - i - 1) * 1000,
      }))
    );
  }, []);

  const fetchRecentWinners = useCallback(async () => {
    const winners = await getRecentWinners();
    if (!winners) {
      setRecentWinners([]);
      return;
    }
    setRecentWinners(
      winners.map((w, i) => ({
        player: w.player,
        reward: BigInt(w.reward),
        timestamp: Date.now() - (winners.length - i - 1) * 1000,
      }))
    );
  }, []);

  const fetchPiCoinData = useCallback(
    async (address: string) => {
      const [claimed, remaining] = await Promise.all([
        hasUserClaimed(address),
        getRemainingSupply(),
      ]);
      setHasClaimed(claimed || false);
      setRemainingSupply(remaining || "0");
    },
    []
  );

  const fetchBettingHistory = useCallback(async () => {
    if (!account) {
      setBets([]);
      return;
    }
    const betCount = await getUserBetCount(account);
    if (!betCount || parseInt(betCount) === 0) {
      setBets([]);
      return;
    }
    const count = parseInt(betCount);
    const betsData: RawBet[] | null = await getUserBets(account, 0, count); 
    if (!betsData) {
      setBets([]);
      return;
    }
  
    setBets(
      betsData.map((bet, i) => ({
        id: (count - 1 - i).toString(),
        amount: ethers.formatUnits(bet.amount, 18),
        betType: bet.betType,
        betBlock: bet.betBlock,
        targetBlock: bet.targetBlock,
        settled: bet.settled,
        refunded: false,
        result: bet.result,
        won: bet.result !== undefined && bet.betType !== undefined ? bet.result === bet.betType : undefined,
      }))
    );
  }, [account]);

  const fetchBoundReferrer = useCallback(async () => {
    if (!account) {
      setBoundReferrer(null);
      return;
    }
    const contract = await getParityGameContractWithSigner();
    if (!contract) return;
    const referrer = await contract.getReferrer(account);
    setBoundReferrer(
      referrer && referrer !== ethers.ZeroAddress ? referrer : null
    );
  }, [account]);

  const fetchAllData = useCallback(async () => {
    if (!account || Date.now() - lastRefresh < 60000) return;
    setLastRefresh(Date.now());
    setIsLoading(true);
    if (!(await ensureNetwork())) {
      toast.error(t.switchToBSCTestnet);
      setIsLoading(false);
      return;
    }
    await Promise.all([
      loadBalance(account),
      loadPiPool(),
      fetchRecentResults(),
      fetchRecentWinners(),
      fetchPiCoinData(account),
      fetchBettingHistory(),
      fetchBoundReferrer(),
    ]).catch(() => toast.error(t.error));
    setIsLoading(false);
  }, [
    account,
    lastRefresh,
    loadBalance,
    loadPiPool,
    fetchRecentResults,
    fetchRecentWinners,
    fetchPiCoinData,
    fetchBettingHistory,
    fetchBoundReferrer,
    t,
  ]);

  const connectWallet = useCallback(async () => {
    setIsLoading(true);
    if (!window.ethereum || !provider) {
      toast.error("MetaMask not detected!");
      setIsLoading(false);
      return;
    }
    if (!(await ensureNetwork())) {
      toast.error(t.switchToBSCTestnet);
      setIsLoading(false);
      return;
    }
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
    setAccount(address);
    await fetchAllData();
    await addPiTokenToWallet();
    toast.success("Wallet connected!");
    setIsLoading(false);
  }, [fetchAllData, t]);

  const disconnectWallet = useCallback(() => {
    setAccount(null);
    setBalance("0");
    setPiPool("0");
    setRecentResults([]);
    setRecentWinners([]);
    setBets([]);
    setIsTokensApproved(false);
    setHasClaimed(false);
    setRemainingSupply("0");
    setBoundReferrer(null);
    toast.success("Wallet disconnected!");
  }, []);

  const approveTokens = useCallback(async () => {
    if (!account) {
      toast.error(t.connectWalletPrompt);
      return;
    }
    setIsLoading(true);
    const contract = await getPiCoinContractWithSigner();
    if (!contract) return;
    try {
      const tx = await contract.approve(parityGameAddress, MaxUint256);
      await tx.wait();
      setIsTokensApproved(true);
      await loadBalance(account);
      toast.success(t.tokensApprovedSuccess);
    } catch {
      toast.error(t.approveFailed);
    } finally {
      setIsLoading(false);
    }
  }, [account, loadBalance, t]);

  const placeBet = useCallback(
    async (isOdd: boolean, amount: string, callback: (betId: string) => void) => {
      if (!account) {
        toast.error(t.connectWalletPrompt);
        return;
      }
      const amountNum = parseFloat(amount);
      if (isNaN(amountNum) || amountNum < 1) {
        toast.error(`Bet amount must be at least 1 PI`);
        return;
      }
      if (amountNum > parseFloat(balance)) {
        toast.error(t.insufficientBalance);
        return;
      }
      const piPoolNum = parseFloat(piPool);
      const maxBet = piPoolNum >= 1e14 ? piPoolNum * 0.01 : Infinity;
      if (amountNum > maxBet) {
        toast.error(`Bet cannot exceed ${maxBet.toFixed(2)} PI`);
        return;
      }
      setIsLoading(true);
      const contract = await getParityGameContractWithSigner();
      if (!contract) return;
      try {
        const betType = isOdd ? 0 : 1;
        const parsedAmount = parseEther(amount);
        const tx = await contract.placeBet(betType, parsedAmount);
        const receipt = await tx.wait();
        const betId = contract.interface
          .parseLog(
            receipt.logs.find(
              (log: { address: string }) => log.address === parityGameAddress
            )!
          )?.args.betId.toString();
        if (betId) {
          callback(betId);
          setBets((prev) => [
            {
              id: betId,
              amount,
              betType,
              betBlock: receipt.blockNumber,
              targetBlock: receipt.blockNumber + 20,
              settled: false,
              refunded: false,
            },
            ...prev,
          ]);
        }
        await fetchAllData();
        toast.success(t.betPlacedSuccess);
      } catch {
        toast.error(t.betFailed);
      } finally {
        setIsLoading(false);
      }
    },
    [account, balance, piPool, fetchAllData, t]
  );

  const confirmClaim = useCallback(
    async (bet: Bet) => {
      if (!account || bet.settled || !bet.id || bet.won === undefined || !bet.won) return;
      setIsLoading(true);
      const contract = await getParityGameContractWithSigner();
      if (!contract) return;
      try {
        const betIndex = bets.findIndex((b) => b.id === bet.id);
        const tx = await contract.settleBet(betIndex);
        await tx.wait();
        setBets((prev) =>
          prev.map((b) =>
            b.id === bet.id ? { ...b, settled: true } : b
          )
        );
        await fetchAllData();
        toast.success("Reward claimed!");
      } catch {
        toast.error(t.error);
      } finally {
        setIsLoading(false);
      }
    },
    [account, bets, fetchAllData, t]
  );

  const claimAllRewards = useCallback(() => {
    if (!account || !bets.some((bet) => !bet.settled && bet.won !== undefined && bet.won)) {
      toast.info(t.noRewards);
      return;
    }
    setShowClaimModal(true);
    setIsModalOpen(true);
  }, [account, bets, t]);

  const handleBindReferrer = useCallback(async () => {
    if (!account || !referrerAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
      toast.error("Invalid referrer address or wallet not connected.");
      return;
    }
    setIsLoading(true);
    const contract = await getParityGameContractWithSigner();
    if (!contract) return;
    try {
      const tx = await contract.bindReferrer(referrerAddress);
      await tx.wait();
      setBoundReferrer(referrerAddress);
      setShowReferModal(false);
      setIsModalOpen(false);
      toast.success("Referrer bound successfully!");
    } catch {
      toast.error(t.error);
    } finally {
      setIsLoading(false);
    }
  }, [account, referrerAddress, t]);

  const handleRefundBet = useCallback(
    async (betId: string) => {
      if (!account) {
        toast.error(t.connectWalletPrompt);
        return;
      }
      setIsLoading(true);
      try {
        if (await refundBet(betId)) {
          setBets((prev) =>
            prev.map((bet) =>
              bet.id === betId ? { ...bet, refunded: true } : bet
            )
          );
          await loadBalance(account);
          await fetchAllData();
          toast.success("Refund successful!");
        } else {
          toast.error(t.refundFailed);
        }
      } catch {
        toast.error(t.refundFailed);
      } finally {
        setIsLoading(false);
      }
    },
    [account, loadBalance, fetchAllData, t]
  );

  useEffect(() => {
    if (!account) {
      setBets([]);
      setBoundReferrer(null);
      setHasClaimed(false);
      return;
    }
    fetchAllData();
    const interval = setInterval(fetchAllData, 60000);
    return () => clearInterval(interval);
  }, [account, fetchAllData]);

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        <header className="flex justify-between items-center p-4 bg-gray-800 shadow-lg">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              setShowNav(true);
              playSound();
            }}
            className="text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 rounded"
          >
            <Bars3Icon className="h-8 w-8" />
          </motion.button>
          <h1 className="text-2xl font-bold text-yellow-400 glow-text">{t.title}</h1>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={account ? disconnectWallet : connectWallet}
            className="flex items-center space-x-2 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black px-4 py-2 rounded-lg shadow-lg interactive"
            disabled={isLoading}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-black"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <>
                <WalletIcon className="h-5 w-5" />
                <span>{account ? t.disconnectWallet : t.connectWallet}</span>
              </>
            )}
          </motion.button>
        </header>

        <main className="p-4">
          {account ? (
            selectedGame === "Parity" ? (
              <>
                <GameSection
                  t={t}
                  play={playSound}
                  piPool={piPool}
                  recentResults={recentResults}
                  formatNumber={formatNumber}
                  isOdd={isOdd}
                  setIsOdd={setIsOdd}
                  betAmount={betAmount}
                  setBetAmount={setBetAmount}
                  balance={balance}
                  isTokensApproved={isTokensApproved}
                  approveTokens={approveTokens}
                  placeBet={placeBet}
                  account={account}
                />
                <ChartsSection
                  t={t}
                  recentResults={recentResults}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-[calc(100vh-80px)]">
                <p className="text-2xl font-bold text-yellow-400 glow-text">
                  {t.developing}
                </p>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center justify-center h-[calc(100vh-80px)]">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={connectWallet}
                className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black px-6 py-3 rounded-lg shadow-lg interactive text-xl font-bold"
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-black"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  t.connectWallet
                )}
              </motion.button>
              <p className="mt-4 text-gray-400 text-center max-w-md">
                {t.testnetTokenInstruction}
              </p>
            </div>
          )}
        </main>

        <Navigation
          t={t}
          play={playSound}
          showNav={showNav}
          setShowNav={setShowNav}
          setShowLeaderboard={setShowLeaderboard}
          setShowHowToPlay={setShowHowToPlay}
          setShowReferModal={setShowReferModal}
          setShowHistoryModal={setShowHistoryModal}
          setShowClaimModal={setShowClaimModal}
          setShowRefundModal={setShowRefundModal}
          setShowTestnetGuideModal={setShowTestnetGuideModal}
          claimAllRewards={claimAllRewards}
          setLanguage={setLanguage}
          language={language}
          account={account}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setSelectedGame={setSelectedGame}
          remainingSupply={remainingSupply}
          fetchAllData={fetchAllData}
          hasClaimed={hasClaimed}
          connectWallet={connectWallet}
        />

        <Modal
          isOpen={showLeaderboard && isModalOpen}
          onClose={() => {
            setShowLeaderboard(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.leaderboard}
        >
          {recentWinners.length === 0 ? (
            <p className="text-gray-400">{t.noBets}</p>
          ) : (
            <ul className="space-y-2">
              {recentWinners.map((winner, i) => (
                <li
                  key={i}
                  className="bg-gray-700 p-3 rounded-lg flex justify-between items-center"
                >
                  <span>
                    {winner.player.slice(0, 6)}...{winner.player.slice(-4)}
                  </span>
                  <span className="text-yellow-400">
                    {formatNumber(winner.reward.toString())} PI
                    <br />
                    <small>{new Date(winner.timestamp).toLocaleString()}</small>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </Modal>

        <Modal
          isOpen={showHowToPlay && isModalOpen}
          onClose={() => {
            setShowHowToPlay(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.howToPlay}
        >
          <p className="text-gray-400">
            Welcome to the Parity Game! Here`s how to play:
            <br />
            1. Choose Odd or Even to place your bet.
            <br />
            2. Enter your bet amount (in PI) and click the Bet button.
            <br />
            3. Wait for the target block (20 blocks after your bet block) to
            generate the result.
            <br />
            4. If the result matches your choice, you win a reward!
            <br />
            5. Check your betting history and recent winners.
          </p>
        </Modal>

        <Modal
          isOpen={showReferModal && isModalOpen}
          onClose={() => {
            setShowReferModal(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.refer}
        >
          {boundReferrer ? (
            <p className="text-gray-400">
              {t.boundReferrer}: {boundReferrer.slice(0, 6)}...
              {boundReferrer.slice(-4)}
            </p>
          ) : (
            <>
              <input
                type="text"
                value={referrerAddress}
                onChange={(e) => setReferrerAddress(e.target.value)}
                placeholder={t.enterReferAddress}
                className="w-full p-2 mb-4 bg-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 interactive"
              />
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleBindReferrer}
                className="w-full py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg shadow-lg interactive"
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-black mx-auto"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  t.confirmBind
                )}
              </motion.button>
            </>
          )}
        </Modal>

        <Modal
          isOpen={showHistoryModal && isModalOpen}
          onClose={() => {
            setShowHistoryModal(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.history}
        >
          {bets.length === 0 ? (
            <p className="text-gray-400">{t.noBets}</p>
          ) : (
            <ul className="space-y-2">
              {bets.map((bet, i) => (
                <li
                  key={i}
                  className="bg-gray-700 p-3 rounded-lg flex justify-between items-center flex-wrap gap-2"
                >
                  <div>
                    <span>ID: {bet.id}</span> |{" "}
                    <span>
                      {bet.amount} PI ({bet.betType === 0 ? t.odd : t.even})
                    </span>{" "}
                    | <span>Block: {bet.betBlock}</span> |{" "}
                    <span>{bet.settled ? "Settled" : "Unsettled"}</span> |{" "}
                    <span>{bet.refunded ? "Refunded" : "Not Refunded"}</span> |
                    {bet.result !== undefined && (
                      <span>
                        Result: {bet.result} ({bet.won !== undefined && bet.won ? "Won" : "Lost"})
                      </span>
                    )}
                  </div>
                  {!bet.settled && !(bet.refunded ?? false) && bet.won !== undefined && bet.won && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => confirmClaim(bet)}
                      className="bg-gradient-to-r from-green-500 to-green-600 text-white px-2 py-1 rounded-lg shadow-lg interactive"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : (
                        t.claimOnChain
                      )}
                    </motion.button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </Modal>

        <Modal
          isOpen={showClaimModal && isModalOpen}
          onClose={() => {
            setShowClaimModal(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.claimReward}
        >
          {bets.filter((bet) => !bet.settled && bet.won !== undefined && bet.won).length === 0 ? (
            <p className="text-gray-400">{t.noRewards}</p>
          ) : (
            <ul className="space-y-2">
              {bets.map(
                (bet, i) =>
                  !bet.settled &&
                  bet.won !== undefined &&
                  bet.won && (
                    <li
                      key={i}
                      className="bg-gray-700 p-3 rounded-lg flex justify-between items-center flex-wrap gap-2"
                    >
                      <div>
                        <span>ID: {bet.id}</span> |{" "}
                        <span>
                          {bet.amount} PI ({bet.betType === 0 ? t.odd : t.even})
                        </span>{" "}
                        | <span>Block: {bet.betBlock}</span> |{" "}
                        <span>Result: {bet.result}</span>
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => confirmClaim(bet)}
                        className="bg-gradient-to-r from-green-500 to-green-600 text-white px-2 py-1 rounded-lg shadow-lg interactive"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        ) : (
                          t.claimOnChain
                        )}
                      </motion.button>
                    </li>
                  )
              )}
            </ul>
          )}
        </Modal>

        <Modal
          isOpen={showRefundModal && isModalOpen}
          onClose={() => {
            setShowRefundModal(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.refund}
        >
          {refundableBets.length === 0 ? (
            <p className="text-gray-400">{t.noRefundableBets}</p>
          ) : (
            <ul className="space-y-2">
              {refundableBets.map((bet, i) => (
                <li
                  key={i}
                  className="bg-gray-800 p-3 rounded-lg flex justify-between items-center flex-wrap gap-2"
                >
                  <div>
                    <span>ID: {bet.id}</span> |{" "}
                    <span>
                      {bet.amount} PI ({bet.betType === 0 ? t.odd : t.even})
                    </span>{" "}
                    | <span>Block: {bet.betBlock}</span>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleRefundBet(bet.id!)}
                    className="bg-gradient-to-r from-red-500 to-red-600 text-white px-2 py-1 rounded-lg shadow-lg interactive"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    ) : (
                      t.refund
                    )}
                  </motion.button>
                </li>
              ))}
            </ul>
          )}
        </Modal>

        <Modal
          isOpen={showTestnetGuideModal && isModalOpen}
          onClose={() => {
            setShowTestnetGuideModal(false);
            setIsModalOpen(false);
            playSound();
          }}
          title={t.getTestnetTokensGuide}
        >
          <p className="text-gray-400">{t.testnetTokenInstruction}</p>
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar={false}
      />
    </ErrorBoundary>
  );
};

export default App;