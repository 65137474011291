import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { getPiTokenBalance, listenForBetPlaced, ensureNetwork } from "./contract";
import { Translations } from "./App";



// Define the props interface for GameSection
interface GameSectionProps {
  t: Translations;
  play: () => void;
  piPool: string;
  recentResults: { result: number; blockNumber: number; timestamp: number }[];
  formatNumber: (value: string | number) => string;
  isOdd: boolean;
  setIsOdd: (value: boolean) => void;
  betAmount: string;
  setBetAmount: (value: string) => void;
  balance: string;
  isTokensApproved: boolean;
  approveTokens: () => Promise<void>;
  placeBet: (
    isOdd: boolean,
    amount: string,
    callback: (betId: string) => void
  ) => Promise<void>;
  account: string | null;
}

const GameSection: React.FC<GameSectionProps> = ({
  t,
  play,
  piPool,
  recentResults,
  formatNumber,
  isOdd,
  setIsOdd,
  betAmount,
  setBetAmount,
  balance,
  isTokensApproved,
  approveTokens,
  placeBet,
  account,
}) => {
  const [latestBetId, setLatestBetId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userBalance, setUserBalance] = useState<string>(balance || "0");

  const fetchUserBalance = useCallback(
    async (address: string) => {
      const fetchedBalance = await getPiTokenBalance(address);
      setUserBalance(fetchedBalance || "0");
      if (!fetchedBalance || parseFloat(fetchedBalance) === 0) {
        toast.info(t.zeroBalanceWarning);
      }
    },
    [t]
  );

  const checkNetwork = useCallback(async () => {
    const success = await ensureNetwork();
    if (!success && account) {
      toast.info(t.switchToBSCTestnet);
    }
    return success;
  }, [t, account]);

  useEffect(() => {
    if (!account) {
      setUserBalance("0");
      setLatestBetId(null);
      return;
    }
    const initialize = async () => {
      await checkNetwork();
      await fetchUserBalance(account);
    };
    initialize();
  }, [account, fetchUserBalance, checkNetwork]);

  useEffect(() => {
    setUserBalance(balance || "0");
  }, [balance]);

  useEffect(() => {
    if (!account) return;
    const cleanup = listenForBetPlaced(account, (betId: string) => {
      setLatestBetId(betId);
      fetchUserBalance(account);
    });
    return () => cleanup();
  }, [account, fetchUserBalance]);

  const sortedResults = [...recentResults].sort(
    (a, b) => b.timestamp - a.timestamp
  );
  const latestResult = sortedResults[0];

  const piPoolNum = parseFloat(piPool);
  const POOL_THRESHOLD = 100_000_000_000_000;
  const maxBetAmount =
    piPoolNum >= POOL_THRESHOLD ? piPoolNum * 0.01 : parseFloat(userBalance);

  const handleBetAmountChange = useCallback(
    (value: string) => {
      const amount = parseFloat(value);
      if (isNaN(amount) || amount < 1) {
        setBetAmount("1");
        toast.info("Minimum bet amount is 1 PI.");
      } else if (amount > maxBetAmount) {
        setBetAmount(maxBetAmount.toString());
        toast.info(
          piPoolNum >= POOL_THRESHOLD
            ? `Maximum bet is ${maxBetAmount.toFixed(2)} PI (1% of PI pool).`
            : `Maximum bet is your balance: ${maxBetAmount.toFixed(2)} PI.`
        );
      } else {
        setBetAmount(value);
      }
    },
    [maxBetAmount, piPoolNum, setBetAmount]
  );

  const handlePercentageBet = useCallback(
    (percent: number) => {
      if (!account) {
        toast.info(t.connectWalletPrompt);
        return;
      }
      const newAmount = parseFloat(userBalance) * percent;
      if (newAmount < 1) {
        setBetAmount("1");
        toast.info("Minimum bet amount is 1 PI.");
      } else if (newAmount > maxBetAmount) {
        setBetAmount(maxBetAmount.toString());
        toast.info(
          piPoolNum >= POOL_THRESHOLD
            ? `Maximum bet is ${maxBetAmount.toFixed(2)} PI (1% of PI pool).`
            : `Maximum bet is your balance: ${maxBetAmount.toFixed(2)} PI.`
        );
      } else {
        setBetAmount(newAmount.toFixed(2));
      }
      play();
    },
    [account, userBalance, maxBetAmount, piPoolNum, setBetAmount, play, t]
  );

  const handlePlaceBet = useCallback(async () => {
    if (!account || !(await checkNetwork())) {
      toast.info(t.connectWalletPrompt);
      return;
    }
    const amount = parseFloat(betAmount);
    if (!betAmount || amount <= 0) {
      toast.info(t.invalidBetAmount);
      return;
    }
    if (amount > parseFloat(userBalance)) {
      toast.info(t.insufficientBalance);
      return;
    }

    setIsLoading(true);
    try {
      await placeBet(isOdd, betAmount, setLatestBetId);
      if (account) await fetchUserBalance(account);
    } catch (error: any) {
      toast.error(`${t.betFailed}: ${error.message || t.error}`);
    } finally {
      setIsLoading(false);
    }
  }, [betAmount, isOdd, placeBet, account, fetchUserBalance, userBalance, checkNetwork, t]);

  const handleApproveTokens = useCallback(async () => {
    if (!account || !(await checkNetwork())) {
      toast.info(t.connectWalletPrompt);
      return;
    }

    setIsLoading(true);
    try {
      await approveTokens();
      if (account) await fetchUserBalance(account);
    } catch (error: any) {
      toast.error(`${t.approveFailed}: ${error.message || t.error}`);
    } finally {
      setIsLoading(false);
    }
  }, [approveTokens, account, fetchUserBalance, checkNetwork, t]);

  const renderBettingSection = () => (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full bg-gray-800 p-6 rounded-lg shadow-lg card-glow card-pulse"
    >
      <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">{t.bet}</h2>
      <div className="flex space-x-4 mb-4">
        {[{ label: t.odd, value: true }, { label: t.even, value: false }].map((option) => (
          <motion.button
            key={option.label}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              setIsOdd(option.value);
              play();
            }}
            className={`flex-1 py-2 rounded-lg shadow-lg ${
              isOdd === option.value
                ? "bg-gradient-to-r from-yellow-500 to-yellow-600 text-black"
                : "bg-gray-700"
            } interactive dynamic-border`}
          >
            {option.label}
          </motion.button>
        ))}
      </div>
      <div className="mb-4">
        <input
          type="number"
          value={betAmount}
          onChange={(e) => handleBetAmountChange(e.target.value)}
          placeholder="Enter bet amount"
          min="1"
          step="0.01"
          className="w-full p-2 bg-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 interactive no-spinner"
          disabled={!account}
        />
      </div>
      <p className="text-sm mb-4">
        {t.balance}: {formatNumber(userBalance)} PI
      </p>
      <div className="flex space-x-2 mb-4">
        {[0.25, 0.5, 1].map((percent) => (
          <motion.button
            key={percent}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handlePercentageBet(percent)}
            className="flex-1 py-2 bg-gray-700 rounded-lg interactive"
            disabled={!account || parseFloat(userBalance) === 0}
          >
            {percent === 1 ? "MAX" : `${percent * 100}%`}
          </motion.button>
        ))}
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleApproveTokens}
        disabled={isTokensApproved || isLoading || !account}
        className="w-full py-3 mb-2 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg shadow-lg interactive dynamic-border disabled:opacity-50"
      >
        {isLoading ? t.loading : t.approveTokens}
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handlePlaceBet}
        disabled={!isTokensApproved || isLoading || !account}
        className="w-full py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg shadow-lg interactive dynamic-border disabled:opacity-50"
      >
        {isLoading ? t.loading : t.bet}
      </motion.button>
      {latestBetId && account && (
        <p className="text-sm mt-4 text-yellow-400">
          Latest Bet ID: {latestBetId}
        </p>
      )}
    </motion.div>
  );

  return (
    <div className="mb-6 max-w-screen-2xl mx-auto px-4">
      <div className="hidden md:flex flex-row justify-between gap-8">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg card-glow card-pulse flex flex-col items-center justify-center"
        >
          <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">
            {t.piPool}
          </h2>
          <motion.p
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            className="text-4xl md:text-5xl font-bold text-white bg-gradient-to-r from-yellow-500 to-yellow-600 px-4 py-2 rounded-lg pulse-latest"
          >
            {formatNumber(piPool)} PI
          </motion.p>
        </motion.div>

        {renderBettingSection()}

        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          className="w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg card-glow card-pulse flex flex-col items-center justify-center"
        >
          <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">
            {t.latestResult}
          </h2>
          {recentResults.length > 0 && latestResult ? (
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              className="flex flex-col items-center"
            >
              <div
                className={`rounded-full w-16 h-16 flex items-center justify-center text-white font-bold text-3xl pulse-latest neon-effect ${
                  latestResult.result % 2 === 1 ? "bg-red-600" : "bg-green-600"
                }`}
              >
                {latestResult.result}
              </div>
              <div className="mt-2 text-center text-sm">
                <p>
                  {t.block}: {latestResult.blockNumber}
                </p>
              </div>
            </motion.div>
          ) : (
            <p className="text-gray-400">{t.noBets}</p>
          )}
        </motion.div>
      </div>

      <div className="md:hidden space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="w-full bg-gray-800 p-6 rounded-lg shadow-lg card-glow card-pulse flex flex-col items-center justify-center"
        >
          <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">
            {t.piPool}
          </h2>
          <motion.p
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            className="text-4xl font-bold text-white bg-gradient-to-r from-yellow-500 to-yellow-600 px-4 py-2 rounded-lg pulse-latest"
          >
            {formatNumber(piPool)} PI
          </motion.p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="w-full bg-gray-800 p-6 rounded-lg shadow-lg card-glow card-pulse flex flex-col items-center justify-center"
        >
          <h2 className="text-2xl font-bold text-yellow-400 glow-text mb-4">
            {t.latestResult}
          </h2>
          {recentResults.length > 0 && latestResult ? (
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              className="flex flex-col items-center"
            >
              <div
                className={`rounded-full w-16 h-16 flex items-center justify-center text-white font-bold text-3xl pulse-latest neon-effect ${
                  latestResult.result % 2 === 1 ? "bg-red-600" : "bg-green-600"
                }`}
              >
                {latestResult.result}
              </div>
              <div className="mt-2 text-center text-sm">
                <p>
                  {t.block}: {latestResult.blockNumber}
                </p>
              </div>
            </motion.div>
          ) : (
            <p className="text-gray-400">{t.noBets}</p>
          )}
        </motion.div>

        {renderBettingSection()}
      </div>
    </div>
  );
};

export default GameSection;