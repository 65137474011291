import React, { useState, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  XMarkIcon,
  ChevronDownIcon,
  CubeIcon,
  UserGroupIcon,
  ShareIcon,
  ClockIcon,
  GiftIcon,
  ArrowUturnLeftIcon,
  TrophyIcon,
  QuestionMarkCircleIcon,
  LanguageIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  PaperAirplaneIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { Translations } from "./App";
import { claimPiTokens, ensureNetwork } from "./contract";

// Define the list of games
const games = [
  "Parity",
  "StarCatcher",
  "PuzzleQuest",
  "MemoryMatch",
  "TreasureHunt",
  "SkyRacer",
  "PixelCraft",
  "WordScramble",
  "MathBlaster",
  "ColorSplash",
  "ShapeSorter",
  "MusicMaestro",
  "TriviaTango",
  "BlockBuilder",
  "AdventurePath",
  "CodeCracker",
  "TimeTraveler",
  "EcoWarrior",
  "SpaceExplorer",
  "ArtStudio",
  "LogicLab",
  "StorySpinner",
  "RhythmRush",
  "PatternPlay",
  "QuestMaster",
  "ScienceSprint",
  "DreamDesigner",
  "NatureNinja",
  "RobotRally",
  "MysteryMansion",
  "SkillStack",
  "FantasyForge",
];

// Custom X Platform Icon Component
const XPlatformIcon: React.FC = () => (
  <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

// Define the props interface for Navigation
interface NavigationProps {
  t: Translations;
  play: () => void;
  showNav: boolean;
  setShowNav: (show: boolean) => void;
  setShowLeaderboard: (show: boolean) => void;
  setShowHowToPlay: (show: boolean) => void;
  setShowReferModal: (show: boolean) => void;
  setShowHistoryModal: (show: boolean) => void;
  setShowClaimModal: (show: boolean) => void;
  setShowRefundModal: (show: boolean) => void;
  setShowTestnetGuideModal: (show: boolean) => void;
  claimAllRewards: () => void;
  setLanguage: (lang: "en" | "zh") => void;
  language: "en" | "zh";
  account: string | null;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setSelectedGame: (game: string) => void;
  remainingSupply: string;
  fetchAllData: () => Promise<void>;
  hasClaimed: boolean;
  connectWallet: () => Promise<void>;
}

const Navigation: React.FC<NavigationProps> = ({
  t,
  play,
  showNav,
  setShowNav,
  setShowLeaderboard,
  setShowHowToPlay,
  setShowReferModal,
  setShowHistoryModal,
  setShowClaimModal,
  setShowRefundModal,
  setShowTestnetGuideModal,
  claimAllRewards,
  setLanguage,
  language,
  account,
  isModalOpen,
  setIsModalOpen,
  setSelectedGame,
  remainingSupply,
  fetchAllData,
  hasClaimed,
  connectWallet,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<{
    games: boolean;
    community: boolean;
  }>({
    games: false,
    community: false,
  });
  const [copied, setCopied] = useState<boolean>(false);

  const checkNetwork = useCallback(async () => {
    const success = await ensureNetwork();
    if (!success) {
      toast.error(t.switchToBSCTestnet);
    }
    return success;
  }, [t]);

  const toggleSection = useCallback(
    (section: "games" | "community") => {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
      play();
    },
    [play]
  );

  const handleCopyReferral = useCallback(async () => {
    if (!account) {
      toast.error(t.connectWalletPrompt);
      return;
    }
    if (!(await checkNetwork())) return;

    const referralLink = `https://picoin.vip/ref/${account}`;
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      play();
      setTimeout(() => setCopied(false), 2000);
    } catch {
      toast.error(t.error);
    }
  }, [account, play, checkNetwork, t]);

  const handleMenuClick = useCallback(
    (action: () => void) => {
      if (isModalOpen) return;
      action();
      setShowNav(false);
      setIsModalOpen(true);
      play();
    },
    [isModalOpen, setShowNav, setIsModalOpen, play]
  );

  const handleClaimPiTokens = useCallback(async () => {
    if (!account) {
      toast.error(t.connectWalletPrompt);
      await connectWallet();
      return;
    }
    if (hasClaimed) {
      toast.info(t.alreadyClaimed);
      return;
    }
    if (!(await checkNetwork())) return;

    setIsLoading(true);
    try {
      const success = await claimPiTokens();
      if (success) {
        toast.success(t.claimPiTokens + " 🎉");
        await fetchAllData();
      } else {
        toast.error(t.error);
      }
    } catch (error: any) {
      toast.error(`${t.error}: ${error.reason || error.message || "Unknown"}`);
    } finally {
      setIsLoading(false);
      setShowNav(false);
    }
  }, [account, hasClaimed, connectWallet, checkNetwork, fetchAllData, t]);

  const gameList = useMemo(
    () =>
      games.map((game) => (
        <li key={game}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              setSelectedGame(game);
              setShowNav(false);
              play();
            }}
            className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
          >
            <CubeIcon className="h-5 w-5 mr-2" />
            {game}
          </motion.button>
        </li>
      )),
    [setSelectedGame, setShowNav, play]
  );

  return (
    <AnimatePresence>
      {showNav && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{ duration: 0.3 }}
          className="fixed top-0 left-0 h-full w-64 bg-gray-900 shadow-lg z-[10001] overflow-y-auto"
        >
          <div className="p-4">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => { setShowNav(false); play(); }}
              className="text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 rounded"
            >
              <XMarkIcon className="h-8 w-8" />
            </motion.button>

            <div className="mt-4">
              <button
                onClick={() => toggleSection("games")}
                className="w-full flex items-center justify-between text-2xl font-bold text-yellow-400 glow-text py-2"
              >
                <div className="flex items-center">
                  <CubeIcon className="h-6 w-6 mr-2" />
                  {t.games}
                </div>
                <ChevronDownIcon className={`h-5 w-5 ${expandedSections.games ? "rotate-180" : ""}`} />
              </button>
              {expandedSections.games && (
                <ul className="mt-4 space-y-2">
                  {gameList}
                </ul>
              )}
            </div>

            <div className="mt-6">
              <button
                onClick={() => toggleSection("community")}
                className="w-full flex items-center justify-between text-2xl font-bold text-yellow-400 glow-text py-2"
              >
                <div className="flex items-center">
                  <UserGroupIcon className="h-6 w-6 mr-2" />
                  {t.community}
                </div>
                <ChevronDownIcon className={`h-5 w-5 ${expandedSections.community ? "rotate-180" : ""}`} />
              </button>
              {expandedSections.community && (
                <ul className="mt-4 space-y-2">
                  <li>
                    <a
                      href="https://picoin.vip"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                      onClick={play}
                    >
                      <GlobeAltIcon className="h-5 w-5 mr-2" />
                      {t.officialWebsite}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/PiCoreTeam"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                      onClick={play}
                    >
                      <XPlatformIcon />
                      {t.x}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/PiCoreTeam"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                      onClick={play}
                    >
                      <PaperAirplaneIcon className="h-5 w-5 mr-2" />
                      {t.telegram}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/pinetwork"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                      onClick={play}
                    >
                      <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />
                      {t.discord}
                    </a>
                  </li>
                </ul>
              )}
            </div>

            {account && (
              <>
                <div className="mt-6 flex items-center">
                  <ShareIcon className="h-6 w-6 mr-2 text-yellow-400" />
                  <h2 className="text-2xl font-bold text-yellow-400 glow-text">{t.refer}</h2>
                </div>
                <ul className="mt-4 space-y-2">
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(() => setShowReferModal(true))}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <ShareIcon className="h-5 w-5 mr-2" />
                      {t.bindRefer}
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleCopyReferral}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <ClipboardDocumentIcon className="h-5 w-5 mr-2" />
                      {copied ? t.copied : t.copyReferralLink}
                    </motion.button>
                  </li>
                </ul>

                <div className="mt-6 flex items-center">
                  <ClockIcon className="h-6 w-6 mr-2 text-yellow-400" />
                  <h2 className="text-2xl font-bold text-yellow-400 glow-text">{t.history}</h2>
                </div>
                <ul className="mt-4 space-y-2">
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(() => setShowHistoryModal(true))}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <ClockIcon className="h-5 w-5 mr-2" />
                      {t.history}
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(claimAllRewards)}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <GiftIcon className="h-5 w-5 mr-2" />
                      {t.claimReward}
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(() => setShowRefundModal(true))}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <ArrowUturnLeftIcon className="h-5 w-5 mr-2" />
                      {t.refund}
                    </motion.button>
                  </li>
                </ul>

                <div className="mt-6 flex items-center">
                  <TrophyIcon className="h-6 w-6 mr-2 text-yellow-400" />
                  <h2 className="text-2xl font-bold text-yellow-400 glow-text">{t.leaderboard}</h2>
                </div>
                <ul className="mt-4 space-y-2">
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(() => setShowLeaderboard(true))}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <TrophyIcon className="h-5 w-5 mr-2" />
                      {t.leaderboard}
                    </motion.button>
                  </li>
                </ul>

                <div className="mt-6 flex items-center">
                  <CurrencyDollarIcon className="h-6 w-6 mr-2 text-yellow-400" />
                  <h2 className="text-2xl font-bold text-yellow-400 glow-text">{t.getTestnetTokens}</h2>
                </div>
                <ul className="mt-4 space-y-2">
                  <li>
                    <div className="text-gray-400 text-sm px-4 mb-2">
                      {t.remainingSupply}: {remainingSupply} PI
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleClaimPiTokens}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive disabled:opacity-50"
                      disabled={isLoading || hasClaimed}
                    >
                      {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white mr-2" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                      ) : (
                        <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                      )}
                      {hasClaimed ? t.alreadyClaimed : t.claimPiTokens}
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(() => setShowTestnetGuideModal(true))}
                      className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                    >
                      <QuestionMarkCircleIcon className="h-5 w-5 mr-2" />
                      {t.getTestnetTokensGuide}
                    </motion.button>
                  </li>
                </ul>
              </>
            )}

            <div className="mt-6 flex items-center">
              <QuestionMarkCircleIcon className="h-6 w-6 mr-2 text-yellow-400" />
              <h2 className="text-2xl font-bold text-yellow-400 glow-text">{t.howToPlay}</h2>
            </div>
            <ul className="mt-4 space-y-2">
              <li>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleMenuClick(() => setShowHowToPlay(true))}
                  className="w-full flex items-center text-left py-2 px-4 rounded-lg bg-gray-800 hover:bg-gray-700 text-white interactive"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5 mr-2" />
                  {t.howToPlay}
                </motion.button>
              </li>
            </ul>

            <div className="mt-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => { setLanguage(language === "en" ? "zh" : "en"); play(); }}
                className="w-full flex items-center justify-center py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg shadow-lg interactive"
              >
                <LanguageIcon className="h-5 w-5 mr-2" />
                {language === "en" ? "Switch to Chinese" : "Switch to English"}
              </motion.button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Navigation;