import { ethers } from "ethers";
import parityGameABI from "./abis/ParityGame.json";
import piCoinABI from "./abis/PiCoin.json";

// Extract ABI data
const parityGameABIData = (parityGameABI as { abi: ethers.InterfaceAbi }).abi;
const piCoinABIData = (piCoinABI as { abi: ethers.InterfaceAbi }).abi;

// Export ethers utilities
export const parseEther = ethers.parseEther;
export const MaxUint256 = ethers.MaxUint256;

// Contract addresses
export const parityGameAddress = "0x2330647408b5FA4F9eab3236B713f7475660cd9A";
export const piCoinAddress = "0x176B2DF746Bf02B9c41FD2a6247d863e80B021E8";

console.log("ParityGame Address:", parityGameAddress);
console.log("PiCoin Address:", piCoinAddress);

// Initialize provider
const provider = new ethers.JsonRpcProvider(
  process.env.REACT_APP_TESTNET_RPC_URL || "https://data-seed-prebsc-1-s1.binance.org:8545/"
);

// Log current block number
provider
  .getBlockNumber()
  .then((block) => console.log("Current block:", block))
  .catch((err) => console.error("Provider error:", err));

// Interfaces for EIP-1193 provider and errors
interface EIP1193Provider {
  request: (args: { method: string; params?: unknown[] | object }) => Promise<unknown>;
  on: (event: string, listener: (...args: unknown[]) => void) => void;
  removeListener: (event: string, listener: (...args: unknown[]) => void) => void;
}

interface ProviderRpcError {
  code: number;
  message: string;
  data?: unknown;
}

interface CustomWindow extends Window {
  ethereum?: EIP1193Provider & ethers.BrowserProvider;
}

declare const window: CustomWindow;

// Check if wallet is available
const isWalletAvailable = (): boolean => {
  return typeof window !== "undefined" && !!window.ethereum;
};

// Wait for Ethereum provider with timeout
const waitForEthereum = async (timeoutMs = 5000): Promise<(EIP1193Provider & ethers.BrowserProvider) | null> => {
  if (!isWalletAvailable()) {
    console.error("Wallet not available: window.ethereum is undefined");
    return null;
  }
  const start = Date.now();
  while (!window.ethereum) {
    if (Date.now() - start >= timeoutMs) {
      console.error("Timeout waiting for window.ethereum");
      return null;
    }
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
  return window.ethereum;
};

// Get signer from provider
const getSignerProvider = async (): Promise<ethers.Signer | null> => {
  console.log("Getting signer provider...");
  const ethereum = await waitForEthereum();
  if (!ethereum || !ethereum.request) {
    console.error("Ethereum provider or request method not available");
    return null;
  }
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    console.log("Connected accounts:", accounts);
    const web3Provider = new ethers.BrowserProvider(ethereum);
    const signer = await web3Provider.getSigner();
    console.log("Signer retrieved:", signer);
    return signer;
  } catch (error) {
    console.error("Error connecting to wallet:", error);
    return null;
  }
};

// Initialize contracts with provider
export const parityGameContract = new ethers.Contract(parityGameAddress, parityGameABIData, provider);
export const piCoinContract = new ethers.Contract(piCoinAddress, piCoinABIData, provider);

console.log("ParityGame Contract:", parityGameContract);
console.log("PiCoin Contract:", piCoinContract);

// Get ParityGame contract with signer
export const getParityGameContractWithSigner = async (): Promise<ethers.Contract | null> => {
  console.log("Getting ParityGame contract with signer...");
  const signer = await getSignerProvider();
  if (!signer) {
    console.error("Failed to get signer for ParityGame contract");
    return null;
  }
  return new ethers.Contract(parityGameAddress, parityGameABIData, signer);
};

// Get PiCoin contract with signer
export const getPiCoinContractWithSigner = async (): Promise<ethers.Contract | null> => {
  console.log("Getting PiCoin contract with signer...");
  const signer = await getSignerProvider();
  if (!signer) {
    console.error("Failed to get signer for PiCoin contract");
    return null;
  }
  return new ethers.Contract(piCoinAddress, piCoinABIData, signer);
};

// Ensure the correct network (BSC Testnet)
export const ensureNetwork = async (): Promise<boolean> => {
  console.log("Ensuring network...");
  const ethereum = await waitForEthereum();
  if (!ethereum || !ethereum.request) {
    console.error("Ethereum provider or request method not available in ensureNetwork");
    return false;
  }

  try {
    const chainId = (await ethereum.request({ method: "eth_chainId" })) as string;
    console.log("Current chainId:", chainId);
    if (chainId.toLowerCase() === "0x61") {
      console.log("Already on BSC Testnet");
      return true;
    }

    console.log("Switching to BSC Testnet...");
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x61" }],
      });
      console.log("Successfully switched to BSC Testnet");
      return true;
    } catch (switchError: unknown) {
      const error = switchError as ProviderRpcError;
      console.error("Error switching chain:", error);
      if (error.code === 4902) {
        console.log("BSC Testnet not found, adding network...");
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x61",
              chainName: "BSC Testnet",
              rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
              nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],
        });
        console.log("BSC Testnet added successfully");
        return true;
      }
      return false;
    }
  } catch (error) {
    console.error("Error in ensureNetwork:", error);
    return false;
  }
};

// Add Pi token to wallet
export const addPiTokenToWallet = async (): Promise<boolean> => {
  console.log("Adding Pi token to wallet...");
  const ethereum = await waitForEthereum();
  if (!ethereum || !ethereum.request) {
    console.error("Ethereum provider or request method not available in addPiTokenToWallet");
    return false;
  }

  const requestParams = {
    type: "ERC20" as const, // 明确指定 type 为字面量
    options: {
      address: piCoinAddress,
      symbol: "PI",
      decimals: 18,
      image: "https://example.com/pi-icon.png",
    },
  };

  console.log("wallet_watchAsset requestParams:", requestParams);

  try {
    if (!requestParams.type || !requestParams.options || !requestParams.options.address) {
      console.error("Invalid params for wallet_watchAsset");
      return false;
    }

    const result = await ethereum.request({
      method: "wallet_watchAsset",
      params: requestParams,
    });
    console.log("wallet_watchAsset result:", result);
    return true;
  } catch (error) {
    console.error("Error in addPiTokenToWallet:", error);
    return false;
  }
};

// Claim Pi tokens
export const claimPiTokens = async (): Promise<boolean> => {
  console.log("claimPiTokens called");
  const contract = await getPiCoinContractWithSigner();
  if (!contract) {
    console.error("Failed to get PiCoin contract with signer");
    return false;
  }
  try {
    console.log("Calling claim() on PiCoin contract...");
    const tx = await contract.claim();
    console.log("Transaction sent:", tx);
    const receipt = await tx.wait();
    console.log("Transaction confirmed:", receipt);
    return true;
  } catch (error) {
    console.error("Error in claimPiTokens:", error);
    throw error;
  }
};

// Check if user has claimed tokens
export const hasUserClaimed = async (address: string): Promise<boolean | null> => {
  try {
    const claimed = await piCoinContract.hasClaimed(address);
    console.log(`Has user ${address} claimed:`, claimed);
    return claimed as boolean;
  } catch (error) {
    console.error("Error in hasUserClaimed:", error);
    return null;
  }
};

// Get Pi token balance
export const getPiTokenBalance = async (address: string): Promise<string | null> => {
  try {
    const balance = await piCoinContract.balanceOf(address);
    const formattedBalance = ethers.formatUnits(balance, 18);
    console.log(`PiToken balance for ${address}:`, formattedBalance);
    return formattedBalance;
  } catch (error) {
    console.error("Error in getPiTokenBalance:", error);
    return null;
  }
};

// Get remaining supply of Pi tokens
export const getRemainingSupply = async (): Promise<string | null> => {
  try {
    const remaining = await piCoinContract.remainingSupply();
    const formattedRemaining = ethers.formatUnits(remaining, 18);
    console.log("Remaining supply:", formattedRemaining);
    return formattedRemaining;
  } catch (error) {
    console.error("Error in getRemainingSupply:", error);
    return null;
  }
};

// Get PiCoin stats
export const getPiCoinStats = async (): Promise<{
  totalSupply: string;
  totalMinted: string;
  remainingSupply: string;
  totalClaims: string;
} | null> => {
  try {
    const [totalSupply, totalMinted, remainingSupply, totalClaims] = await Promise.all([
      piCoinContract.totalSupply(),
      piCoinContract.totalMinted(),
      piCoinContract.remainingSupply(),
      piCoinContract.totalClaims(),
    ]);
    return {
      totalSupply: ethers.formatUnits(totalSupply, 18),
      totalMinted: ethers.formatUnits(totalMinted, 18),
      remainingSupply: ethers.formatUnits(remainingSupply, 18),
      totalClaims: totalClaims.toString(),
    };
  } catch (error) {
    console.error("Error in getPiCoinStats:", error);
    return null;
  }
};

// Get PI pool balance
export const getPiPool = async (): Promise<string | null> => {
  try {
    const poolBalance = await piCoinContract.balanceOf(parityGameAddress);
    const formattedBalance = ethers.formatUnits(poolBalance, 18);
    console.log(`PI pool balance for ParityGame contract: ${formattedBalance}`);
    return formattedBalance;
  } catch (error) {
    console.error("Error in getPiPool:", error);
    return null;
  }
};

// Listen for BetPlaced events
export const listenForBetPlaced = (playerAddress: string, callback: (betId: string) => void): () => void => {
  console.log(`Listening for BetPlaced events for player ${playerAddress}...`);
  const contract = new ethers.Contract(parityGameAddress, parityGameABIData, provider);
  const handler = (player: string, amount: bigint, betId: bigint) => {
    if (player.toLowerCase() === playerAddress.toLowerCase()) {
      console.log(`BetPlaced event detected for player ${player}, betId: ${betId}`);
      callback(betId.toString());
    }
  };
  contract.on("BetPlaced", handler);
  return () => {
    console.log(`Removing BetPlaced listener for player ${playerAddress}`);
    contract.off("BetPlaced", handler);
  };
};

// Interfaces for Bet and Winner
interface Bet {
  amount: bigint;
  betType: number;
  betBlock: number;
  targetBlock: number;
  settled: boolean;
}

interface Winner {
  player: string;
  reward: bigint;
}

// Get user bets
export const getUserBets = async (address: string, start: number, count: number): Promise<Bet[] | null> => {
  if (start < 0 || count <= 0) {
    console.error("Invalid parameters for getUserBets: start < 0 or count <= 0");
    return null;
  }
  try {
    const bets = await parityGameContract.getUserBets(address, start, count);
    console.log(`Fetched ${bets.length} bets for ${address}`);
    return bets as Bet[];
  } catch (error) {
    console.error("Error in getUserBets:", error);
    return null;
  }
};

// Get user bet count
export const getUserBetCount = async (address: string): Promise<string | null> => {
  try {
    const count = await parityGameContract.getUserBetCount(address);
    console.log(`User bet count for ${address}:`, count.toString());
    return count.toString();
  } catch (error) {
    console.error("Error in getUserBetCount:", error);
    return null;
  }
};

// Refund a bet
export const refundBet = async (betId: string): Promise<boolean> => {
  console.log(`Refunding bet with ID ${betId}...`);
  const contract = await getParityGameContractWithSigner();
  if (!contract) {
    console.error("Failed to get ParityGame contract with signer for refund");
    return false;
  }
  try {
    const tx = await contract.refundExpired(betId);
    console.log("Refund transaction sent:", tx);
    await tx.wait();
    console.log("Refund transaction confirmed");
    return true;
  } catch (error) {
    console.error("Error in refundBet:", error);
    return false;
  }
};

// Get recent results
export const getRecentResults = async (): Promise<number[] | null> => {
  try {
    const results = await parityGameContract.getRecentResults();
    const formattedResults = (results as bigint[]).map((r) => Number(r));
    console.log("Recent results:", formattedResults);
    return formattedResults;
  } catch (error) {
    console.error("Error in getRecentResults:", error);
    return null;
  }
};

// Get recent winners
export const getRecentWinners = async (): Promise<Winner[] | null> => {
  try {
    const winners = await parityGameContract.getRecentWinners();
    console.log("Recent winners:", winners);
    return winners as Winner[];
  } catch (error) {
    console.error("Error in getRecentWinners:", error);
    return null;
  }
};